import { Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"

const TableConatiner = styled.div`
  overflow-x: auto;
  margin-bottom: 50px;
`

const Table = styled.table`
  width: 100%;
  overflow: scroll;
  column-gap: 50px;
  border-spacing: 50px 0;
  min-width: 720px;

  & tr:first-child {
    height: 50px;
  }

  & tr:first-child > * {
    border: none;
    border-bottom: 1px solid #bfbfbf;
  }

  & tr > * {
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;
    border: 1px solid #bfbfbf;
    border-top: none;
  }

  & .dark-bg {
    background-color: #002060;
  }

  & .light-bg {
    background-color: #e2efda;
  }

  & .gray-bg {
    background-color: #bfbfbf;
  }

  & .dark-text {
    color: #002060;
  }

  & .light-text {
    color: #0070c0;
  }

  & .text-lg {
    font-size: 1.25rem;
  }

  & .bold {
    font-weight: bolder;
  }

  & .p {
    padding: 5px;
  }
`

const Button = styled(Link)`
  background-color: #3bc6bd;
  border-radius: 12px;
  padding: 10px 30px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const PricingPage = () => (
  <Layout>
    <h2 className="text-primary">Always pricing fairly</h2>
    <br />
    <h1>Choose the option that suits you best:</h1>
    <br />
    <br />

    <TableConatiner>
      <Table>
        <tbody>
          <tr>
            <th className="dark-text bold text-lg gray-bg">Select</th>
            <td>
              <Button to="#">BUY!</Button>
            </td>
            <td>
              <Button to="#">BUY!</Button>
            </td>
          </tr>

          <tr>
            <th className="dark-text bold text-lg p">Price</th>
            <td className="light-text bold text-lg p">$50</td>
            <td className="light-text bold text-lg p">$500</td>
          </tr>

          <tr>
            <th>License Type</th>
            <td>Monthly Subscription</td>
            <td>Yearly Subscription</td>
          </tr>

          <tr>
            <th>Delivery</th>
            <td>Desktop App</td>
            <td>Desktop App</td>
          </tr>

          <tr>
            <th>License</th>
            <td>Floating</td>
            <td>Floating</td>
          </tr>

          <tr>
            <th>Maximun acquisition</th>
            <td>Unlimited</td>
            <td>Unlimited</td>
          </tr>

          <tr>
            <th>Maximun number of users</th>
            <td>1</td>
            <td>1</td>
          </tr>

          <tr>
            <th className="gray-bg bold">Core Features</th>
            <td className="dark-bg"></td>
            <td className="dark-bg"></td>
          </tr>

          <tr>
            <th>Case Manager</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Dedicated Cases Database</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Case info in Sqlite Database</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th className="gray-bg bold">Basic Features</th>
            <td className="dark-bg"></td>
            <td className="dark-bg"></td>
          </tr>

          <tr>
            <th>Audio/Video Recording</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Evidence Acquisition Report</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Network Packets Capture &amp; SSL/TLS Keys Export</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Evidence Chain Of Custody</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Download Page Source Code</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Evidence Screenshots</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Forsint online Evidence Validation</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Evidence Multi Hashed</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>User Activity Audit Logs</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th className="gray-bg bold">Advance Acquisition Features</th>
            <td className="dark-bg"></td>
            <td className="dark-bg"></td>
          </tr>

          <tr>
            <th>Page and Element Autoscroll</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Enhanced Video User Click</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Comments &amps; Replies Expansion</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Custom Proxy Server</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Evidence PageShots</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>

          <tr>
            <th>Offline Evidence Review Tool</th>
            <td className="light-bg dark-text">YES</td>
            <td className="light-bg dark-text">YES</td>
          </tr>
        </tbody>
      </Table>
    </TableConatiner>
  </Layout>
)

export default PricingPage
